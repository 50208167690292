<template>
  <BaseModal class="!top-[17%] !left-[28%]" @close="closeModal">
    <template #default>
      <div class="overflow-hidden">
        <div class="agreement-text max-h-[520px] max-w-[840px] overflow-y-auto">
          <SubscriptionAgreement />
        </div>
        <div class="flex items-center p-4 border-t border-gray-200">
          <CheckBoxInput name="agreement" :required="true" @change="toggleAgree" id="agreement" />
          <label class="ml-2 text-base text-gray-700" for="agreement"
            >I hereby agree to the SaaS agreement and order
          </label>
        </div>
        <div class="flex justify-end p-4">
          <BaseButton
            text="Agree and proceed"
            bg-color="green"
            :disabled="!agreed"
            @on-click="confirmAgreement"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import BaseModal from '@/components/BaseModal.vue'
import CheckBoxInput from '@/components/inputs/CheckBoxInput.vue'
import BaseButton from '@/components/BaseButton.vue'
import SubscriptionAgreement from './SubscriptionAgreement.vue'
// Define the events this component can emit
const emit = defineEmits(['close', 'agree'])

// State to track if the user has agreed
const agreed = ref(false)

// Toggle the agreed state when the checkbox changes
const toggleAgree = () => {
  agreed.value = !agreed.value
}

// Emit the agree event when the user confirms
const confirmAgreement = () => {
  if (agreed.value) {
    emit('agree')
    closeModal()
  }
}

// Emit the close event to close the modal
const closeModal = () => {
  emit('close')
}
</script>
