<template>
  <BaseCard class="max-w-[860px] card-shadow m-auto">
    <template #header>
      <LeftArrowIcon class="cursor-pointer absolute left-[20px]" @click="router.back()" />
      <div class="pt-6 px-8 inter-medium text-2xl text-gray-500 border-b-2">Account & Security</div>
    </template>
    <template #main>
      <div class="m-auto p-8 w-full">
        <div class="mb-6 inter-medium text-lg text-gray-500">Account Details</div>
        <form>
          <Input
            class="mb-6"
            title="full name"
            name="name"
            type="text"
            disabled
            max-length="100"
            min-length="2"
            label-size="small"
            :value="store.getUserName"
          />
          <Input
            class="mb-6"
            title="email"
            name="email"
            type="email"
            disabled
            label-size="small"
            :value="user.email"
          />
        </form>
        <div class="mt-3 mb-6 inter-medium text-lg text-gray-500">Security</div>
        <form @submit.prevent="updatePassword">
          <PasswordInput
            class="mb-6"
            title="Current Password"
            name="current_password"
            min-length="6"
            max-length="100"
            label-size="small"
            required
            :value="currentPassword"
            @input-change="(value) => (currentPassword = value)"
            :validation-errors="passwordValidationErrors?.current_password"
          />
          <PasswordInput
            class="mb-6"
            title="New Password"
            name="password"
            min-length="6"
            max-length="100"
            label-size="small"
            required
            :value="password"
            @input-change="(value) => (password = value)"
            :validation-errors="passwordValidationErrors?.password"
          />
          <PasswordInput
            class="mb-2"
            title="Confirm New Password"
            name="confirm_password"
            min-length="6"
            max-length="100"
            label-size="small"
            required
            :value="confirmPassword"
            @input-change="(value) => (confirmPassword = value)"
            :validation-errors="passwordValidationErrors?.confirm_password"
          />
          <BaseButton
            class="ml-auto mt-8"
            disable
            text="Change password"
            type="submit"
            :loading="loading"
          />
        </form>
        <div class="mt-3 mb-6 inter-medium text-lg text-gray-500">Integrations</div>
        <div class="flex gap-5">
          <img v-for="integration in user.integrations" :src="integration.image_path" class="rounded-full w-20 h-20 border-2">
        </div>
        <div v-if="Object.entries(user.integrations).length === 0">
          <div v-if="isSyncingATS">
            ATS integration is syncing. It will take a few minutes.
          </div>
          <div v-else>
            <BaseButton
              v-if="!linkToken"
              class="ml-0 mt-8"
              text="Request ATS Integration"
              type="button"
              :loading="integrationLoading"
              @on-click="requestIntegration"
            />
            <merge-link
              v-else
              :linkToken="linkToken" v-bind="{ onSuccess }"
            >
              <template v-slot:button="props" >
                <BaseButton
                  class="ml-0 mt-8"
                  text="Choose ATS"
                  type="button"
                  @click="props.onClick"
                />
              </template>
            </merge-link>
          </div>
        </div>

      </div>
      <Toaster
        v-if="toasterMessage"
        :message="toasterMessage"
        :color="failedUpdate ? 'red' : 'green'"
        :time="5000"
        @hide-toaster="toasterMessage = null"
      />
    </template>
  </BaseCard>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import MergeLink from '@mergeapi/vue-merge-link';

import Input from '@/components/inputs/Input.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCard from '@/components/BaseCard.vue'
import Toaster from '@/components/Toaster.vue'
import PasswordInput from '@/components/inputs/PasswordInput.vue'
import LeftArrowIcon from '@/components/icons/LeftArrowIcon.vue'
import { useUserStore } from '@/stores/user'

const store = useUserStore()
const user = ref(store.user)
const router = useRouter()
const password = ref()
const confirmPassword = ref()
const currentPassword = ref()
const failedUpdate = ref(false)
const loading = ref(false)
const toasterMessage = ref()
const integrationLoading = ref(false)
const passwordValidationErrors = ref()
const linkToken = ref(null)
const originId = ref(null)
const isSyncingATS = ref(false)


const updatePassword = async () => {
  passwordValidationErrors.value = null
  loading.value = true

  toasterMessage.value = null
  failedUpdate.value = false

  try {
    await axios.put(`${import.meta.env.VITE_LOOKALIKES_API}/api/profile/update-password`, {
      current_password: currentPassword.value,
      password: password.value,
      confirm_password: confirmPassword.value
    })
    toasterMessage.value = 'Password updated!'
    failedUpdate.value = false
    clearPassword()
  } catch (error) {
    if (error.response.status === 422) {
      passwordValidationErrors.value = error.response.data.errors
    } else {
      captureException(error)
      failedUpdate.value = true
      toasterMessage.value = error.response.message
    }
  } finally {
    loading.value = false
  }
}

const clearPassword = () => {
  currentPassword.value = null
  password.value = null
  confirmPassword.value = null
}
const requestIntegration = async () => {
  integrationLoading.value = true

  try {
    const response = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/integration/link-token`)
    linkToken.value = response.data.token
    originId.value = response.data.origin_id
  } catch (error) {
    captureException(error)
    failedUpdate.value = true
    toasterMessage.value = error.response.message
  } finally {
    integrationLoading.value = false
  }
}

const onSuccess = async (token: String) => {
  try {
    const response = await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/integration/token`, {
      public_token: token,
      origin_id: originId.value
    })

    linkToken.value = null
    originId.value = null
    isSyncingATS.value = true
  } catch (error) {
    captureException(error)
    failedUpdate.value = true
    toasterMessage.value = error.response.message
  }
}

</script>
