<template>
  <BaseButton
    v-if="Object.entries(user.integrations).length > 0"
    :text="text"
    :loading="loading"
    :disabled="isAddedToAts || isAddedToAtsLocal"
    :bg-color="
      isAddedToAts || isAddedToAtsLocal ? 'darkBlue' : 'default'
    "
    @click="addToAts"
  />
</template>

<script lang="ts" setup>
import { computed, defineEmits, defineProps, ref } from 'vue'
import BaseButton from '@/components/BaseButton.vue'
import { useUserStore } from '@/stores/user'


import { captureException } from '@sentry/vue'
import axios from 'axios'

interface IProps {
  isAddedToAts: boolean,
  shortlistId: number
}

const emit = defineEmits(['failed', 'succeed'])
const props = defineProps<IProps>()
const store = useUserStore()
const user = ref(store.user)

const loading = ref(false)
const isAddedToAtsLocal = ref(false)

const text = computed(() =>
  props.isAddedToAts || isAddedToAtsLocal.value ? 'Added to ATS' : 'Add to ATS'
)

const addToAts = async () => {
  loading.value = true

  try {
    await axios.post(`${import.meta.env.VITE_LOOKALIKES_API}/api/integration/candidate`, {
      shortlist_id: props.shortlistId
    })

    isAddedToAtsLocal.value = true
    emit('succeed')
  } catch (error) {
    captureException(error)

    emit('failed', error.response.data.message ?? 'Oops something went wrong. please contact our support team')
  } finally {
    loading.value = false
  }
}
</script>
